import Form from "./components/form";
import duck from "./duck.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={duck} className="App-logo" alt="logo" />
        <Form />
      </header>
    </div>
  );
}

export default App;
